////////////////////////////////////////////////////
//---------------- C O L O R ----------------------
///////////////////////////////////////////////////


//to ignore cli warning about deprecated mixin
// $enable-deprecation-messages: false;
// $ignore-warning             : true;


// stylelint-disable-next-line scss/dollar-variable-default
$primary   : #1E74FD !default;
$secondary : #673BB7 !default;
$success   : #10d876 !default;
$info      : #2754e6 !default;
$warning   : #FE9431 !default;
$danger    : #E50202 !default;
$light     : #f7f7f7 !default;
$dark      : #343a40;

// Body
$body-bg   : #fff;
$body-color: #515184;



////////////////////////////////////////////////////
//---------------- FONT WEIGHT ----------------------
///////////////////////////////////////////////////

$fw-100: 100 !default;
$fw-200: 200 !default;
$fw-300: 300 !default;
$fw-400: 400 !default;
$fw-500: 500 !default;
$fw-600: 600 !default;
$fw-700: 700 !default;
$fw-800: 800 !default;
$fw-900: 900 !default;

$fws: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$fws: map-merge(
  (
    "100": $fw-100,
    "200": $fw-200,
    "300": $fw-300,
    "400": $fw-400,
    "500": $fw-500,
    "600": $fw-600,
    "700": $fw-700,
    "800": $fw-800,
    "900": $fw-900
  ),
  $fws
);


////////////////////////////////////////////////////
//---------------- F O N T S ----------------------
///////////////////////////////////////////////////

// Google Font
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');




$headings-font-weight   : 400;

$font-size-base         : 1rem;
$font-weight-base       : 400 !default;


$h1-font-size           : 24px;
$h2-font-size           : 20px;
$h3-font-size           : 18px;
$h4-font-size           : 16px;
$h5-font-size           : 14px;
$h6-font-size           : 12px;


$display1-size			: 70px;
$display2-size			: 56px;
$display3-size			: 42px;
$display4-size			: 34px;

$text-muted : #AEAED5;
$colors     : () !default;

$heading-primary-size: 1.8rem;
$sub-heading-size    : 1.6rem !default;
$grid-gutter-width   : 30px;
$dropdown-lik-color  : $body-color;
$border-color        : #E1E1F0;
$headings-color      :#111 !default;
$grid-breakpoints    : (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400);
$container-max-widths: (sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1199px) !default;

$input-btn-focus-width: 0rem !default;
$border-radius-base   : 4px !default;

$shadow-color: rgba(160, 159, 202, 0.15);



////////////////////////////////////////////////////
//---------------- HEADER ----------------------
///////////////////////////////////////////////////
$header-bg:transparent;
$header-padding:20px;
$header-menu-font-size:16px;
$header-menu-font-color:#888;
$header-avater-radius:35px;
$header-search-height:40px;
$header-search-width:250px;
$header-search-border-radius:5px; 
$header-search-border-color:#ddd;

////////////////////////////////////////////////////
//---------------- BANNER ----------------------
///////////////////////////////////////////////////
$banner-height:500px;
$banner-border-radius:7px;
$banner-title-font-color:#fff;
$banner-title-font-size:68px;
$banner-subtitle-font-color:#fff;
$banner-subtitle-font-size:14px;
$banner-tag-font-color:#ddd;
$banner-tag-font-size:14px;
$banner-btn-height:40px;
$banner-btn-width:200px;
$banner-btn-font-size:13px; 
$banner-btn-font-color:#fff;

////////////////////////////////////////////////////
//---------------- SLIDER ----------------------
///////////////////////////////////////////////////
$slider-border-radius:6px;
$slider-headline-size:22px;
$slider-headline-color:#222;
$slider-post-title-size:18px;
$slider-post-title-color:#fff;
$slider-post-subtitle-size:12px;
$slider-post-subtitle-color:#ddd;
$slider-post-icon-size:20px;

////////////////////////////////////////////////////
//---------------- PAGE NAV ----------------------
///////////////////////////////////////////////////
$pagenav-padding:100px; 
$pagenav-post-title-size:46px;
$pagenav-post-title-color:#111;
$pagenav-post-subtitle-size:14px;
$pagenav-post-subtitle-color:#666;

////////////////////////////////////////////////////
//---------------- LANGUAGE ----------------------
///////////////////////////////////////////////////
$language-border-radius:6px;
$language-height:120px;
$language-post-title-size:14px;
$language-post-title-color:#fff;

////////////////////////////////////////////////////
//---------------- CREW ----------------------
///////////////////////////////////////////////////
$crew-width:150px;
$crew-post-title-size:14px;
$crew-post-title-color:#222;

////////////////////////////////////////////////////
//---------------- FOOTER ----------------------
///////////////////////////////////////////////////
$footer-title-font-color:#ddd;
$footer-title-font-size:24px;
$footer-search-input-height:50px;
$footer-search-input-font-size:12px; 
$footer-link-title-font-size:11px;
$footer-link-title-font-color:#ddd;
$footer-link-font-size:12px;
$footer-link-font-color:#999;
$owl-image-path: "~owl.carousel/src/img/";

@import "./abstract/abstract";
@import '~bootstrap/scss/bootstrap.scss';
@import "~owl.carousel/src/scss/owl.carousel";
@import "~owl.carousel/src/scss/owl.theme.default";

@import "../css/themify-icons.css";
@import "../css/video-player.css";

@import "./base/base";
@import "./components/components";
@import "./pages/about-us";
@import "./pages/contact-us";
@import "./pages/sign-up";
@import "./pages/faq";
@import "./pages/terms";

body {font-family: "Montserrat", sans-serif;}

a {
    cursor: pointer !important;
}

.search-wrapper {
    padding: 100px;
    background-color: #f5f5f5;
    border-radius: 10px;
    @include respond("lg") {
        padding: 50px 15px; 
    }
}

.navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
    display: none;
    height: 40px; 
    @include respond("lg") {
        display: inline-block;
    }

}

.navbar-toggler-icon {
    width: 27px; 
    height: 2px; 
    background-color: #999;
    position: relative;
    top:-2px;
    &:after {
        content: '';
        width: 27px; 
        height: 2px; 
        background-color: #999;
        position: absolute;
        top:-8px;
        left:0;
    }
    &:before {
        content: '';
        width: 27px; 
        height: 2px; 
        background-color: #999;
        position: absolute;
        bottom:-8px;
        left:0;
    }

}

.featured-container-title {
    margin-top: 50px;
    margin-bottom: 10px;
    h2 {
        color: #222;
        font-size: 22px;
        font-weight: 600;
        margin: 10px 0 25px 0;
    }
}

.featured-container {
    border: 1px solid rgba(215,199,133, 0.6);
    margin-bottom: 10px;
    height: 600px;
}

@include media-breakpoint-down(md) {
    .featured-container {
        height: 300px;
        margin-bottom: 0;
    }
}

.search-wrap-slide {
    .owl-nav {display: none;}
}

@media (min-width: 992px) {
    .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
}

@media (max-width: 991px) {
    .md-mb25 {
        margin-bottom: 25px; 
    }
    .footer-wrapper p {
        width: 100%;
    }
    .navbar-nav > li {border-bottom: 1px solid #E1E1F0;margin: 0 15px;padding: 7px 15px; display: block; width: 100%; padding:  20px; }
    .header-wrapper .user-avater,
    .header-wrapper .search-div,
    .header-wrapper .bttn {display: none; }
    .header-wrapper.posfixed {padding:  15px; position: fixed; top:0; left:0; width: 100%; z-index: 100; background-color: #fff; }
}

@media (max-width: 768px) {
    .middle-footer .col-lg-2 {margin-bottom: 25px;  }
    .owl-theme .owl-nav.disabled + .owl-dots {bottom:50px; }
    .pt150 {padding-top: 70px; }
    .col-xs-6 {        flex: 0 0 50%;        max-width: 50%;    }
}

@media (max-width: 576px) {
    .banner-wrapper .slide-wrapper,
    .banner-wrapper .left-wrap a {display: none;  }

    .owl-nav {top:-63px; right: 100px;}
    .slide-wrapper .slide-slider-full,
    .category-slider,
    .slide-wrapper .slide-slider {width: 130%;}
    .banner-wrapper .left-wrap div.icon-bttn {right: 25%; }
    .banner-wrapper .owl-dots{display: none; }
}
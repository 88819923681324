.slide-wrapper {
    .custom-container-height {
        max-height: 550px;
    }
    h2 {
        color: $slider-headline-color;
        font-size: $slider-headline-size; 
        font-weight: 600; 
        margin-bottom: 0; 
        margin:10px 0; 
        @include respond("sm") {
           font-size: 18px;
           margin-bottom: 0;
           margin-top: 0;  
        }
    }
    .read-more {
        font-size: 13px; 
        font-weight: 500; 
        color: #333;
    }
    .slide-one {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
        margin-bottom: 15px;
        /*border-radius:$slider-border-radius; */
        overflow: hidden;
        position: relative;
        background-color: #000000;
        display: block;
        width: 100%;
        @include respond("sm") {
            min-height: 390px;
        }
        &:hover {
            .slide-image {
                &:after {height: 20%;}
                &:before {opacity: 1; }
            }
        }
        &:after {
            content: '';
            position: absolute;
            left:0;
            top:0;
            width: 100%;
            height: 100%;
            /*background-color:rgba(33, 24, 19,0.2) */
            
        }        
        .slide-image {
            position: relative;
            @include respond("sm") {
                height: 130px;
            }
            img {width: 100%;} 
            &:before {
                content: '';
                width: 50px;
                height: 50px;
                background-color: var(--f7-theme-color);
                /* top:50%; */
                top:40%;
                left:50%;
                opacity: 1;
                margin-left: -25px; 
                position: absolute;
                background-size: 25px;
                background-position:center right 10px;
                border-radius:40px; 
                background-repeat: no-repeat;
                background-image: url(../../images/play.png);
                z-index: 3; 
                transition: all 0.4s ease;
                @include respond("sm") {
                     background-color: #f00; opacity: 1; right:0; width: 25px; height: 25px; border-radius: 50%; top: 70%; left:50%; padding: 30px 30px 30px 27px; background-size: 35px; background-position: center right 8px;
                }

            }
            &:after {
                content: '';
                position: absolute;
                left:0;
                bottom:0;
                width: 100%;
                height: 30%;
                /*background:linear-gradient(to top, #211813, transparent);*/
                transition: all 0.4s ease;
                @include respond("sm") {
                    height: 80%;
                }
            }
        }
        .slide-content {
            padding: 15px 18px;  
            position: relative;
            z-index: 2; 
            @include respond("sm") {
                padding: 10px;
                bottom:0;
                left:0;
                width: 100%;
                position:absolute;
                background: #000000;
            }
            h2 {
                font-size: $slider-post-title-size; 
                font-weight: 600; 
                color: $slider-post-title-color;
                margin-bottom: 10px; 
                @include respond("sm") {
                    font-size: 15px; line-height: 1.3; margin-bottom: 8px;
                }
                img {
                    width: $slider-post-icon-size; 
                    float: right;
                    
                }
            }
            p {
                font-size: $slider-post-subtitle-size;
                color: $slider-post-subtitle-color;
                margin-bottom: 5px;
            }
            .tag {
                font-size: $slider-post-subtitle-size; 
                color: $slider-post-subtitle-color;
                margin-right: 15px; 
                b {
                    color: #fff;
                }
            }
        }
    }
    .slide-two {
        .slide-image {
            height: 200px; 
            background-size: cover;
            background-position: center center;
            border-radius:6px; 
            overflow: hidden;
            &:before {
                content: '';
                width: 40px;
                height: 40px;
                top:auto;
                bottom:18px;
                left:35px;
                margin-left: -25px; 
                background-size: 20px;
                background-position:center right 8px;
                border-radius:40px; 
                background-repeat: no-repeat;
                position: absolute;
                opacity: 1;
                background-image: url(../../images/play.png);
                z-index: 3; 
                @include respond("sm") {
                    bottom:15px !important; 
                }
            }
            &:after {
                content: '';
                position: absolute;
                left:0;
                bottom:0;
                width: 100%;
                height: 50%;
                background:linear-gradient(to top, #211813, transparent);
                transition: all 0.4s ease;
            }
        }
        .slide-content {
            position: absolute;
            bottom:0;
            width: 100%;
            left: 0; 
            padding: 15px 10px 10px 60px;   
            z-index: 2; 
            h2 {
                margin-bottom: 0;
                font-size: 16px; 
            }
            .tag {
                position: relative;
                top:-2px; 
                margin-right: 5px; 
            }
        }
    }
    .slide-three {
        .slide-image {
             
            &:before {
                background-color: transparent;
                content: '';
                top:auto;
                bottom: 28px;
                right: 37px;
                left:auto;
                margin-left: -25px; 
                position: absolute;
                background-size: 20px;
                background-image: url(../../images/play.png);
                z-index: 3; 
            }
            
        }
        .slide-content {
            position: absolute;
            bottom:0;
            width: 100%;
            left: 0; 
            padding: 15px 10px 10px 15px;   
            z-index: 2; 
            h2 {
                margin-bottom: 0;
                font-size: 16px; 
            }
            .tag {
                position: relative;
                top:-2px; 
                margin-right: 5px; 
            }
        }
    }
}
.signup-container {
  margin-top: 50px;
  .contact-us-form {
    color: #000;
    border: 1px solid rgba(215, 199, 133, 0.4);
    padding: 20px;
    background-color: #fff;
    input {
      border: 1px solid #000;
    }
    textarea {
      border: 1px solid #000;
    }
    button {
      background-color: var(--f7-theme-color);
      border: 1px solid #f00;
    }
    a {
      color: #000;
      &:hover {
        border-bottom: 1px solid #000;
      }
    }
  }
}
//  DARK THEME
:root {--f7-theme-color: #132977;}

.theme-dark {
    background-color: #000000;
    
    .member-ul li.active a b,
    .price-wrap .left-price h2,
    .form-div h2,
    .slide-wrapper h2 {color: #eee;}
    .header-wrapper .search-div {border:1px #555 solid;}
    .header-wrapper .nav-menu li a {color: #ddd !important;}
    .form-div {background-color: #293145;}

    .header-wrapper .logo {color: lightsteelblue;}
    
    
    .price-wrap .middle-price h6,
    .form-div p,
    .form-div label p,
    .form-div .sign-up-text {color: #ddd;}

    .form-div label p a,
    .form-div .sign-up-text a {color: #fff; margin-left: 5px; }
    .navbar-toggler {color: rgba(255, 255, 255, 1);border-color: rgba(255, 255, 255, 1); }
}

.theme-dark .header-wrapper .logo .light {display: none;}
.theme-dark .header-wrapper .logo .dark {display: inline-block;}

.color-theme-red {
    --f7-theme-color: #ff3b30;
    --f7-theme-color-rgb: 255, 59, 48;
    --f7-theme-color-shade: #ff1407;
    --f7-theme-color-tint: #ff6259
}

.color-theme-green {
    --f7-theme-color: #4cd964;
    --f7-theme-color-rgb: 76, 217, 100;
    --f7-theme-color-shade: #2cd048;
    --f7-theme-color-tint: #6ee081
}

.color-theme-blue {
    --f7-theme-color: #132977;
    --f7-theme-color-rgb: 33, 150, 243;
    --f7-theme-color-shade: #1E74FD;
    --f7-theme-color-tint: #1E74FD;
}

.color-theme-pink {
    --f7-theme-color: #ff2d55;
    --f7-theme-color-rgb: 255, 45, 85;
    --f7-theme-color-shade: #ff0434;
    --f7-theme-color-tint: #ff5676
}

.color-theme-yellow {
    --f7-theme-color: #ffcc00;
    --f7-theme-color-rgb: 255, 204, 0;
    --f7-theme-color-shade: #d6ab00;
    --f7-theme-color-tint: #ffd429
}

.color-theme-orange {
    --f7-theme-color: #ff9500;
    --f7-theme-color-rgb: 255, 149, 0;
    --f7-theme-color-shade: #d67d00;
    --f7-theme-color-tint: #ffa629
}

.color-theme-purple {
    --f7-theme-color: #9c27b0;
    --f7-theme-color-rgb: 156, 39, 176;
    --f7-theme-color-shade: #7e208f;
    --f7-theme-color-tint: #b92fd1
}

.color-theme-deeppurple {
    --f7-theme-color: #673ab7;
    --f7-theme-color-rgb: 103, 58, 183;
    --f7-theme-color-shade: #563098;
    --f7-theme-color-tint: #7c52c8
}

.color-theme-lightblue {
    --f7-theme-color: #5ac8fa;
    --f7-theme-color-rgb: 90, 200, 250;
    --f7-theme-color-shade: #32bbf9;
    --f7-theme-color-tint: #82d5fb
}

.color-theme-teal {
    --f7-theme-color: #009688;
    --f7-theme-color-rgb: 0, 150, 136;
    --f7-theme-color-shade: #006d63;
    --f7-theme-color-tint: #00bfad
}

.color-theme-lime {
    --f7-theme-color: #cddc39;
    --f7-theme-color-rgb: 205, 220, 57;
    --f7-theme-color-shade: #bac923;
    --f7-theme-color-tint: #d6e25c
}

.color-theme-deeporange {
    --f7-theme-color: #ff6b22;
    --f7-theme-color-rgb: 255, 107, 34;
    --f7-theme-color-shade: #f85200;
    --f7-theme-color-tint: #ff864b
}

.color-theme-gray {
    --f7-theme-color: #8e8e93;
    --f7-theme-color-rgb: 142, 142, 147;
    --f7-theme-color-shade: #79797f;
    --f7-theme-color-tint: #a3a3a7
}

.color-theme-white {
    --f7-theme-color: #ffffff;
    --f7-theme-color-rgb: 255, 255, 255;
    --f7-theme-color-shade: #ebebeb;
    --f7-theme-color-tint: #ffffff
}

.color-theme-black {
    --f7-theme-color: #000000;
    --f7-theme-color-rgb: 0, 0, 0;
    --f7-theme-color-shade: #000000;
    --f7-theme-color-tint: #141414
}



.color-theme-brown {
    --f7-theme-color: #D2691E;
    --f7-theme-color-rgb: 255, 107, 34;
    --f7-theme-color-shade: #f85200;
    --f7-theme-color-tint: #ff864b
}

.color-theme-darkgreen {
    --f7-theme-color: #228B22;
    --f7-theme-color-rgb: 142, 142, 147;
    --f7-theme-color-shade: #79797f;
    --f7-theme-color-tint: #a3a3a7
}

.color-theme-deeppink {
    --f7-theme-color: #FFC0CB;
    --f7-theme-color-rgb: 255, 255, 255;
    --f7-theme-color-shade: #ebebeb;
    --f7-theme-color-tint: #ffffff
}

.color-theme-darkorchid {
    --f7-theme-color: #9932cc;
    --f7-theme-color-rgb: 0, 0, 0;
    --f7-theme-color-shade: #000000;
    --f7-theme-color-tint: #141414
}


.full-wrap {
	@media (min-width: 1599px) {
		.container-fluid {
			padding-left: 200px; 
			padding-right: 200px; 
		}

	}
    @media (max-width: 1600px) {
        .container-fluid {
            padding-left: 15px; 
            padding-right: 15px; 
        }

    }
    
	.owl-carousel .owl-stage-outer {
		overflow: visible;
		.owl-item {
		    opacity: 0.4;
		}
		.owl-item.active {
    		opacity: 1;
		}
	}
}

@media (min-width: 1200px) {
        
    .service-wrapper .container-fluid {
        padding-left: 200px; 
        padding-right: 200px; 
        .owl-carousel .owl-stage-outer {
            overflow: visible;
            .owl-item {
                opacity: 0.4;
            }
            .owl-item.active {
                opacity: 1;
            }
        }
    }

}
.about-us {
  margin-top: 50px;
  .about-us-text {
    h5 {
      font-size: 16px;
    }
    p {
      padding: 10px;
    }
  }
  .about-us-image {
    padding: 10px;
    padding-top: 30px;
    img {
      height: 400px;
    }
  }
}
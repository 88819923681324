.owl-theme .owl-nav [class*='owl-']:hover {
    background-color: transparent !important;
}
.owl-theme .owl-nav [class*='owl-']:focus {
    outline: none !important;
    border:none !important;
}
.owl-nav {
    position: absolute;
    top:-75px; 
    right:0;
    button{
        margin:0 !important;
    }
    img {
        width: 35px; 
    }
}

.slider-small-width {
    .owl-stage-outer {
        overflow: hidden !important;
    }
}

.slide-border {
    .owl-items {
        border: 1px solid rgba(215, 199, 133, 0.4);
        .slide-one {
            margin-bottom: 0 !important;
        }
    }
}

@include media-breakpoint-down(md) {
    .slide-border {
        .owl-items {
            border: none;
            .slide-one {
                border: 1px solid rgba(215, 199, 133, 0.4);
                max-width: 77%;
            }
        }
    }
}


.feedback-wrap {
    padding: 100px 0;
    // background-color: #f8f9fb;
    background:linear-gradient(to bottom, #d0dfff, #f0f4fc);
    @include respond("md")  {padding:70px 0 50px; }
    h3 {
        font-size: 16px; 
        font-weight: 600; 
    }
    .quote {
        color: var(--f7-theme-color); 
        position: absolute; 
        height: 60px;
        width: 60px; 
        margin-left: 30px;
        @include respond("md") {
            margin-left: 15px; 
            margin-top: -20px; 
        }
    }
    .feedback-div {
        width: 100%;
        position: relative;
        float: left;
        margin-top:50px; 
        margin-bottom: 130px;
        @include respond("md") {
            margin-bottom: 50px; 
        }

        h4 {
            font-size: 26px; 
            line-height: 48px;
            font-weight: 300; 
            color: #000;
            display: block;
            margin-bottom: 40px;
            @include respond("lg") { 
                font-size: 22px;  line-height: 42px;  
            }
            @include respond("md") {
                font-size: 18px;  line-height: 36px;  
            }
        }
        .avater {
            float: left;
            position: relative;
            width: 200px; 
            img {
                width: 48px;
                height: 48px; 
                float: left;
                display: inline-block;
                margin-right: 10px; 
            }
            h6 {
                font-size: 14px; 
                font-weight: 500; 
                color: #111;
                display: block;
                margin-top: 7px; 
                margin-bottom: 5px; 
            }
            span {
                display: block;
                color: #aaa;
                font-size: 11px; 
            }
        }
    }
    .brand-icon{
        img {
            width: 70px; 
        }
    }
    .owl-dots {
        position: absolute;
        right:0;
        bottom:130px;
        .owl-dot.active span {
            background-color: var(--f7-theme-color) !important;
        } 
        span {
            width: 8px !important; 
            height: 8px !important; 
            margin:4px !important; 
        }
    }
}
.service-wrapper {
    padding: 100px 0; 
    @include respond("md") {
        padding-top: 50px;
        padding-bottom: 50px;  
    }
    .symptom .owl-stage-outer {overflow: visible;}
    .slide-wrapper {
        margin-top: 100px;
    }
    .mobile-banner {
        width: 1000px;
        @include respond("lg") {
            width: 100%;
        }
    }

}

.banner-wraper {
    padding: 100px 0 0; 
    position: relative;
    
    &:after {
        content: '';
        background-color: #d0dfff;
        // background:linear-gradient(to bottom, #d0dfff, #f0f4fc);
        top:0;
        left:0;
        width: 100%;
        height: 70%;
        position: absolute;
        z-index: -1;
        @include respond("sm") {
            height: 85%;
        }
    }
    h2 {
        font-size: 60px;
        font-weight: 700;
        line-height: 1.3; 
        color: var(--f7-theme-color);
        @include respond("xxl") {
            font-size: 50px;
            line-height: 1.2; 
        }
        @include respond("sm") {
            font-size: 32px; 
        }
    }
    h4 {
        font-weight: 500; 
        margin-top: 20px; 
        @include respond("xxl") {
            font-size: 16px;
            line-height: 26px;  
        }
    }
    .play-bttn {
        width: 80px;
        margin-bottom: 30px; 
        background-color: rgba(255, 255, 255, 0.2);  
        border-radius: 50%;
        animation: videobtn 1.9s linear infinite;
        @include respond("sm") {
            width: 65px;
            height: 65px; 

        }
    }
    .banner-img {
        max-width: 1200px; 
        margin-top: 50px; 
        @include respond("xxl") {
            width: 100%;
        }
    }
    form {
        overflow: hidden;
        border-radius:2px; 
        input {
            line-height: 65px;
            width: 70%;
            background-color: #fff;
            border:0;
            outline: none; 
            display: inline-block;
            padding: 0 15px;
            float: left;
            font-size: 14px;
            box-shadow:0px 5px 10px rgba(0, 0, 0, 0.1);
        }
        button {
            text-transform: uppercase;
            float: left;
            width: 30%;
           line-height: 65px; 
           text-align: center;
           background-color: var(--f7-theme-color); 
           border:0;
           outline: none; 
           color: #fff;
           font-size: 12px;
           font-weight: 600;
           letter-spacing: 1px; 
           box-shadow:0px 5px 10px rgba(0, 0, 0, 0.1);
        }
    }

}


.service-div {
    background: #fff;
    box-shadow: 0px 30px 60px rgba(160, 159, 202, 0.45);
    padding: 40px;
    border-radius: 5px;
    position: relative;
    margin-top: 0px;
    min-height: auto;
    @include respond("xxl") {
        padding: 25px; 
    }
    img {
        width: 100px !important; 
        margin-bottom: 20px; 
    }
    h4 {
        font-size: 20px; 
        font-weight: bold;
    }
    p {
        font-size: 14px;
        line-height: 28px;
        color: #666;
        padding-right: 10%;
    }
    a {
        font-size: 15px;
        margin-bottom: 5px;
        font-weight: 500; 
        display: block;
        color: #999;

    }
    .map {
        width: 70px !important; 
        position: relative;
        left:-10px;  
        margin-bottom: 0 !important;
    }
    
    .icon-div {-webkit-box-sizing: content-box;box-sizing: content-box;width: 55px;height: 60px;position: relative;margin: 0px 0px 25px;font-size: 24px;-webkit-border-radius: 50% / 14%;border-radius: 50% / 14%;color: white;text-align: center;line-height: 64px;-o-text-overflow: clip;text-overflow: clip;z-index: 1;}
    .icon-div i {font-size: 24px; }
    .icon-div:before {-webkit-box-sizing: content-box;box-sizing: content-box;position: absolute;content: "";top: 10%;right: -5.5%;bottom: 10%;left: -5%;border: none;-webkit-border-radius: 7% / 50%;border-radius: 7% / 50%;-o-text-overflow: clip;text-overflow: clip;text-shadow: none;z-index: -1;}
    .icon-div.one-no:before,
    .icon-div.one-no {background-color: #7142f8; }
    .icon-div.two-no:before,
    .icon-div.two-no {background-color: #fa4c9a; }
    .icon-div.three-no:before,
    .icon-div.three-no {background-color: #ecbf24; }
    .icon-div.four-no:before,
    .icon-div.four-no {background-color: #35bfe7; }
    .icon-div.five-no:before,
    .icon-div.five-no {background-color: #f38650; }
    .icon-div.six-no:before,
    .icon-div.six-no {background-color: #5cbd2c; }

}

.price-table {
    padding-bottom: 120px; 
    @include respond("md")  {
        padding-bottom: 50px; 
    }
    h4 {
        font-size: 17px; 
        font-weight: bold;
        color: #111;
        margin-bottom: 20px; 
    }
    span {
        font-size: 13px;
        line-height: 24px;
        color: #666;
        padding-right: 10%;
        display: block;
    }

    p {
        font-size: 14px;
        line-height: 28px;
        color: #222;
        padding-right: 10%;
        margin-bottom: 15px; 
        padding-left: 30px; 
        position: relative;
        i {position: absolute; left:0; top:7px; }
    }
    .price-tab {
        overflow: hidden;
        padding: 40px 70px;
        box-shadow: 0 20px 61px rgba(65, 62, 101, 0.14);
        border-radius: 5px;
        background-color: #ffffff;
        width: 90%;
        margin: 0 auto; 
        span {
            font-size: 13px; 
            font-weight: 400;
            color: #999;
            line-height: 24px; 
            border-radius:3px; 
            &.active {
                background-color: #ffe4db;
                color: #f27b51;
                display: inline-block;
                text-align: center;
                padding:  0 30px; 
            }
        }
        h2 {
            font-size: 50px; 
            color: #333;
            font-weight: 700; 
            margin-top: 30px; 
            margin-bottom: 30px; 
            i {
                font-size: 20px;
                font-weight: 500; 
                font-style: normal;
                margin-right: 5px; 
                vertical-align: super;
            }
            b {
                font-size: 14px; 
                font-weight: 600; 
                color: #444;
            }
        }
        p {
            padding: 0;
            color: #777; 
        }
        .btn {
            margin-top: 20px; 
            width: 200px;
            line-height: 60px; 
            border-radius:40px; 
            border:1px #ddd solid; 
            color: #666;
            font-size: 12px; 
            font-weight: 600; 
            text-transform: uppercase;
            left: 1px; 
            padding:  0; 
            display: inline-block;
            &.active {
                color: #fff;
                background-color: var(--f7-theme-color);
                border:0;
            }
            i {
                position: relative;
                top:2px; 
                font-size: 14px; 
                margin-left: 5px; 
                display: inline-block;
            }
        }
    }
}

@keyframes videobtn{
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
    0 0 0 10px rgba(255, 255, 255, 0.2),
    0 0 0 30px rgba(255, 255, 255, 0.2);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.2),
    0 0 0 30px rgba(255, 255, 255, 0.2),
    0 0 0 50px rgba(7255, 255, 255, 0);
  }
} 

@keyframes videobtn_red{
  0% {
    box-shadow: 0 0 0 0 rgba(236, 57, 139, 0.1),
    0 0 0 10px rgba(236, 57, 139, 0.2),
    0 0 0 50px rgba(236, 57, 139, 0.3);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(236, 57, 139, 0.2),
    0 0 0 50px rgba(236, 57, 139, 0.2),
    0 0 0 100px rgba(236, 57, 139, 0);
  }
} 
.terms-container {
  margin-top: 50px;
  .terms{
    color: #000;
    border: 1px solid rgba(215, 199, 133, 0.4);
    padding: 20px;
    background-color: #fff;
    input {
      border: 1px solid #000;
    }
    textarea {
      border: 1px solid #000;
    }
    ul {
      padding: 10px;
      margin-left: 30px;
      li {
        padding: 5px;
        list-style-type: circle;
      }
    }
    .card-header {
      background-color: #000;
      color: #ffffff;
      button {
        color: #ffffff;
      }
    }
    .card-body {
      background-color: #000;
      color: #fff;
      border-radius: 0 !important;
      p {
        color: #fff;
      }
    }
    a {
      color: #000;
      &:hover {
        border-bottom: 1px solid #000;
      }
    }
  }
}
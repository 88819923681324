
.backdrop {
    position: fixed;
    top:0;
    z-index: -1;
    left:0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.0);
    opacity: 0;
    transition:all .3s ease;
    &.active {
        z-index: 999;
        background-color: rgba(0,0,0,0.7);
        opacity: 0.7;
    } 
    &.open {
        z-index: 999;
        background-color: rgba(0,0,0,0.95);
        opacity: 0.95;
    } 

}
.switchcolor {
    z-index: 1000;
    position: fixed;
    cursor: pointer;
    top:20%;
    right:0;
    background-color: #fff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    width: 60px;
    height: 60px; 
    text-align: center;
    border-radius: 5px 0px 0px 5px;
    padding: 12px;
    transition:all .3s ease;
    &.active {
        right:-60px; 
    } 
    img {
        opacity: 0.5;
        width: 100%;
        -webkit-animation: fa-spin 2s infinite linear;
        animation: fa-spin 2s infinite linear;
    }
}
.switchcolor-wrap {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    top:20%;
    right:-330px;
    background-color: #fff;
    z-index: 1000;
    position: fixed;
    padding: 20px 20px 30px 20px; 
    width: 330px; 
    border-radius: 5px 0px 0px 5px;
    transition:all .3s ease;
    &.active {
        right:0px; 
    }
    h2 {
        color: transparent;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #000;
        font-size: 60px;
        letter-spacing: 2px;
        font-weight: 700;
    }
    h4 {
        margin-top: 25px; 
        font-size: 16px; 
        margin-bottom: 20px; 
        display: block;
        color: #222;
        font-weight: 600;

    }

    ul {
        overflow: hidden;
        li {
            display: inline-block;
            float: left;
            margin-right: 8px; 
            .item-content {
                display: inline;
                padding-left: 0; 
                position: relative;
                
                .ti-check {
                    left: 13px;
                    top: 1px !important;
                    &:before {
                        color: #fff ;
                        opacity: 0;
                    }
                }
            }
        }   
    }   
    .circle-color {
        width: 40px;
        height: 40px;
        border-radius:50px; 
        display: inline-block;
        cursor: pointer;
    }
    label.item-radio input[type=radio] {
        display: none;
    }
    label.item-radio input[type=radio]:checked~.ti-check:before {
        opacity: 1;
    }
    label.item-radio input[type=radio]~.ti-check {
        position: absolute;
        top: 50%;
        margin-top: -11px;
        right: calc(var(--f7-safe-area-right) + 10px);
    }
    .toggle-div {
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
        border: 1px #eee solid;
    }
    .sheet-close{
        position: absolute;
        top:15px;
        right: 10px;
        color: #444;
        i {
            font-size: 20px; 
        }
    }
}

.toggle {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    box-sizing: border-box;
    align-self: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    cursor: pointer;
    input[type=checkbox] {
        display: none;
    }
    .toggle-icon {
        z-index: 0;
        margin: 0;
        padding: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        position: relative;
        transition: .3s;
        box-sizing: border-box;
        display: block;
        cursor: pointer;
        width: 40px;
        height: 22px;
        border-radius: 20px;  
        border:1px #ddd solid;
        background: #fff;;
        &:before {
            content: '';
            position: absolute;
            left: 2px;
            top: 2px;
            height: calc(20px - 4px);
            width: calc(20px - 4px);
            border-radius: 18px;;
            box-sizing: border-box;
            background: #fff;
            box-shadow: 0 2px 4px rgba(0, 0, 0, .3);
            z-index: 1;
            transition-duration: .3s;
            transform: scale(1);
        }
        &:after {
            height: calc(20px - 4px);
            width: calc(20px - 4px);
            top: 2px;
            left: 2px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, .3);
            border-radius: calc(20px - 4px);
            background: #fff;
            position: absolute;
            z-index: 2;
            transform: translateX(0px);
            transition-duration: .3s;
            content: '';
        }
    }
}
.toggle input[type=checkbox]:checked+.toggle-icon {
    background: #ff3b30;;
}

.toggle input[type=checkbox]:checked+.toggle-icon:after {
    transform: translateX(20px);
}
.toggle input[type=checkbox]:checked+.toggle-icon:before {
    opacity: 0; 
}

@-webkit-keyframes fa-spin {0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(359deg);transform:rotate(359deg)}}@keyframes fa-spin{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(359deg);transform:rotate(359deg)}}